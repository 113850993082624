.bg {
  &--transparent {
    background: transparent !important;
  }

  &--orange {
    background-color: $orange;
  }

  &--yellow {
    background-color: $yellow;
  }

  &--yellow2 {
    background-color: $yellow2;
  }

  &--gray-100 {
    background-color: $gray-100;
  }

  &--gray-300 {
    background-color: $gray-300;
  }

  &--gray-500 {
    background-color: $gray-500;
  }

  &--gray-600 {
    background-color: $gray-600;
  }

  &--white {
    background-color: $white;
  }
}

.section {
  &--title {
    z-index: 10;

    &--decorator {
      margin-top: -20px;
      height: 10px;
      width: 149px;
      background-color: $orange;
      z-index: 0;
    }
  }

  &--expertise {
    background-color: $gray-100;

    &--features {
      max-width: 11.1111rem;
      margin-left: 2.5556rem;
      margin-right: 2.5556rem;

      &--images {
        background-color: $yellow2;
      }
    }

    &--tagline {
      font-size: 0.7778rem;
    }
  }

  &--focus {
    &--banner {
      margin-top: -242px !important;
    }

    &--content {
      margin-top: -158px !important;
    }
  }
}

.featured {
  &--image {
    &--bg {
      background-color: $gray-300;
    }
  }

  // &--content {
  //   width: 50% !important;
  // }
}

.testimonial {
  &--card {
    border-radius: 1.1111rem;
    box-shadow: 2px 3px 12px #0000000F;
  }

  &--quote {
    font-size: 11.25rem;
    color: $orange;
    line-height: 1;
    margin-bottom: -120px;
  }

  &--caption {
    margin-left: 3.3333rem;
    margin-right: 3.3333rem;
    margin-bottom: 0 !important;
  }

  &--dark {
    background-color: $gray-500;
  }

  &--overlap {
    margin-top: -1.75rem !important;
  }
}

.circled {
  &-half {
    &-yellow2 {
      &-up {
        height: 332px !important;
        background-image: url("images/circle-half-yellow2-up.png");
        background-size: 100% 100%;
        background-repeat: no-repeat;
      }

      &-down {
        height: 332px !important;
        background-image: url("images/circle-half-yellow2-down.png");
        background-size: 100% 100%;
        background-repeat: no-repeat;
      }
    }
  }

  &-end {
    &-orange {
      height: 72px;
      background-image: url("images/circle-end-orange.png");
      background-size: 100% 100%;
      background-repeat: no-repeat;
    }

    &-white {
      height: 72px;
      background-image: url("images/circle-end-white.png");
      background-size: 100% 100%;
      background-repeat: no-repeat;
    }
  }

  &-start {
    &-white {
      height: 72px;
      background-image: url("images/circle-start-white.png");
      background-size: 100% 100%;
      background-repeat: no-repeat;
    }

    &-half {
      height: 352px;
      background-image: url("images/circle-half-large.png");
      background-size: 100% 100%;
      background-repeat: no-repeat;
      background-color: $white;
    }
  }

  &--double {
    &-yellow {
      &-up {
        height: 60px !important;
        background-image: url("images/double-half-circle-yellow-up.png");
        background-size: 100% 100%;
        background-repeat: no-repeat;
      }

      &-down {
        height: 60px !important;
        background-image: url("images/double-half-circle-yellow-down.png");
        background-size: 100% 100%;
        background-repeat: no-repeat;
      }
    }
  }
}

.triangle {
  &--yellow {
    &-up {
      height: 246px !important;
      background-image: url("images/triangle-yellow-up.png");
      background-size: 100% 100%;
      background-repeat: no-repeat;
    }

    &-down {
      height: 246px !important;
      background-image: url("images/triangle-yellow-down.png");
      background-size: 100% 100%;
      background-repeat: no-repeat;
    }
  }
}

.project {
  &--images {
    width: 13.75rem !important;
    height: 13.75rem !important;
    background-color: $gray-400;
    
    &--active {
      background-color: $gray-600 !important;
    }
  }

  &--arrow {
    width: 2.5rem;
    height: 1.25rem;
  }
}

.tab {
  &--show {
    display: flex !important;
  }

  &--hide {
    display: none !important;
  }
}

.btn {
  &--tab {
    width: 38px !important;
  }
}

.contact--form {
  width: 50% !important;
}

@media(max-width: 36rem) {
  .contact--form {
    width: 100% !important;
  }

  .circled {
    &-half {
      &-yellow2 {
        &-up {
          height: 200px !important;
        }
  
        &-down {
          height: 166px !important;
        }
      }
    }

    &--double {
      &-yellow {
        &-up {
          height: 30px !important;
        }
      }
    }
  }

  .triangle {
    &--yellow {
      &-up {
        height: 123px !important;
      }
  
      &-down {
        height: 123px !important;
      }
    }
  }

  .section {
    &--focus {
      &--banner {
        margin-top: -142px !important;
      }
      &--content {
        margin-top: -85px !important;
      }
    }
  }
}