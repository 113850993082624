.header {
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;

  &--bg {
    &--white {
      background-color: $white;
    }

    &--dark {
      background-color: $orange;
    }

    &--light {
      background-color: $gray-100;
    }
  }

  &--link {
    font-weight: $font-weight-bold;
    border-bottom: none;
    margin-bottom: 3px;

    &--dark {
      &:hover {
        border-bottom: solid 3px $secondary;
        margin-bottom: 0 !important;
      }

      &:active {
        border-bottom: solid 3px $secondary;
        margin-bottom: 0 !important;
      }
    }

    &--light {
      &:hover {
        border-bottom: solid 3px $yellow;
        margin-bottom: 0 !important;
      }

      &:active {
        border-bottom: solid 3px $yellow;
        margin-bottom: 0 !important;
      }
    }
  }
}

.mobile {
  &--menu {
    display: none;
    margin-top: 144px !important;
    height: calc(100% - 144px) !important;
  }
}

.btn--menu--close {
  display: none;
}