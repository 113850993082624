.logo {
  &--main {
    width: 7.0625rem !important;
    height: 4rem !important;
  }
}

.image {
  &--expertise {
    width: 34.9375rem !important;
    height: 30.1875rem !important;
  }

  &--companies {
    margin: 2rem 0;
  }

  &--tech-core {
    width: 25.1875rem !important;
    height: 24.375rem !important;
  }

  &--lexifi--about {
    width: 31.5rem !important;
    height: 22.6875rem !important;
  }

  &--consult {
    width: 36.4375rem !important;
    height: 43.3125rem !important;
  }

  &--contact {
    width: 10rem !important;
    height: 10rem !important;
  }

  &--featured {
    width: 22rem !important;
    height: 22rem !important;
  }

  &--partnership {
    width: 15.75rem !important;
    height: 11.5rem !important;
  }

  &--team {
    width: 27.75rem !important;
    height: 27.75rem !important;
  }

  &--connect {
    width: 16.25rem !important;
    height: 16.25rem !important;
  }

  &--implementation {
    width: 100% !important;
    height: 100% !important;
  }
}

@media(max-width: 36rem) {
  .image {
    &--expertise {
      width: 100% !important; 
      height: 100% !important;
      max-width: 36rem !important;
      max-height: 27.125 !important;
    }

    &--featured {
      width: 13.75rem !important;
      height: 13.75rem !important;
    }

    &--team {
      width: 18.75rem !important;
      height: 18.75rem !important;
    }

    &--connect {
      width: 13.75rem !important;
      height: 13.75rem !important;
    }

    &--tech-core {
      width: 100% !important; 
      height: 100% !important;
      max-width: 36rem !important;
      max-height: 27.125rem !important;
    }

    &--lexifi--about {
      width: 18.875rem !important;
      height: 13.625rem !important;
    }

    &--consult {
      width: 23.4375rem !important;
      height: 28rem !important;
    }

    &--implementation {
      width: 70% !important;
      height: 70% !important;
    }

    &--contact {
      width: 4rem !important;
      height: 4rem !important;
    }
  }
}

@media(max-width: 64rem) {
  .image {
    &--consult {
      width: 25rem !important;
      height: 29.75rem !important;
    }

    &--tech-core {
      width: 17.25rem !important;
      height: 16.75rem !important;
    }
  }
}
