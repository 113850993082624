.footer {
  background-color: $gray-300;

  &--logo {
    width: 5.6265rem;
    height: 3.5rem;
  }

  &--text {
    font-size: 0.875rem;
  }

  &--icon {
    width: 1.25rem;
    height: 1.25rem;
  }
}

@media(min-width: 75rem) {
  .footer {
    &--paragraph {
      max-width: 15.625rem !important;
    }
  }
}