
$yellow: #F5DEA4;
$yellow2: #F9F2E0;
$orange: #EBC876;
$gray-100: #fafafa;
$gray-200: #0000000F;
$gray-300: #e7e7e7;
$gray-400: #E1DED9;
$gray-500: #6F6F6E;
$gray-600: #707070;
$gray-700: #565654;

$primary: $orange;
$secondary: $gray-700;

$yiq-text-dark: $gray-500;
$yiq-text-light: $orange;
$enable-responsive-font-sizes: true;



$link-color: $secondary;
$link-hover-decoration: none;
$border-color: $gray-500;
$font-size-base: 1.125rem;
$h1-font-size: $font-size-base * 2.67;
$h6-font-size: $font-size-base * 0.69;
$input-btn-font-size: $font-size-base / 1.125;
// Sizing remap
// This variable affects the `.h-*` and `.w-*` classes.
$sizes: () !default;
// stylelint-disable-next-line scss/dollar-variable-default
$sizes: map-merge(
  (
    10: 10%,
    20: 20%,
    25: 25%,
    30: 30%,
    33: 33%,
    40: 40%,
    45: 45%,
    50: 50%,
    55: 55%,
    60: 60%,
    65: 65%,
    70: 70%,
    75: 75%,
    80: 80%,
    85: 85%,
    90: 90%,
    95: 95%,
    100: 100%,
    auto: auto
  ),
  $sizes
);

$spacer: 1rem !default;
$spacers: () !default;
// stylelint-disable-next-line scss/dollar-variable-default
$spacers: map-merge(
  (
    0: 0,
    1: ($spacer * .25),
    2: ($spacer * .5),
    3: $spacer,
    4: ($spacer * 1.5),
    5: ($spacer * 3),
    6: ($spacer * 4),
    5p: 5%,
    10p: 10%,
    15p: 15%,
    20p: 20%,
    25p: 25%,
    30p: 30%,
    35p: 35%,
    40p: 40%,
    45p: 45%,
    50p: 50%,
  ),
  $spacers
);

$font-family-montserrat: Montserrat, Roboto, Helvetica, Arial, sans-serif;
$font-family-raleway: Raleway, Montserrat, Roboto, Helvetica, Arial, sans-serif;
$font-family-base: $font-family-montserrat;

$btn-font-family: $font-family-raleway;
$btn-font-weight: 700;
