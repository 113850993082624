// Top Function Import
@import "../../node_modules/bootstrap/scss/_functions.scss";

// Overrided import here
@import "./_variables.scss";

// Continue bootstrap import
@import "../../node_modules/bootstrap/scss/_variables.scss";
@import "../../node_modules/bootstrap/scss/_mixins.scss";
@import "../../node_modules/bootstrap/scss/_root.scss";
@import "../../node_modules/bootstrap/scss/_reboot.scss";
@import "../../node_modules/bootstrap/scss/_type.scss";
@import "../../node_modules/bootstrap/scss/_images.scss";
@import "../../node_modules/bootstrap/scss/_code.scss";
@import "../../node_modules/bootstrap/scss/_grid.scss";
@import "../../node_modules/bootstrap/scss/_tables.scss";
@import "../../node_modules/bootstrap/scss/_forms.scss";
@import "../../node_modules/bootstrap/scss/_buttons.scss";
@import "../../node_modules/bootstrap/scss/_transitions.scss";
@import "../../node_modules/bootstrap/scss/_dropdown.scss";
@import "../../node_modules/bootstrap/scss/_button-group.scss";
@import "../../node_modules/bootstrap/scss/_input-group.scss";
@import "../../node_modules/bootstrap/scss/_custom-forms.scss";
@import "../../node_modules/bootstrap/scss/_nav.scss";
@import "../../node_modules/bootstrap/scss/_navbar.scss";
@import "../../node_modules/bootstrap/scss/_card.scss";
@import "../../node_modules/bootstrap/scss/_breadcrumb.scss";
@import "../../node_modules/bootstrap/scss/_pagination.scss";
@import "../../node_modules/bootstrap/scss/_badge.scss";
@import "../../node_modules/bootstrap/scss/_jumbotron.scss";
@import "../../node_modules/bootstrap/scss/_alert.scss";
@import "../../node_modules/bootstrap/scss/_progress.scss";
@import "../../node_modules/bootstrap/scss/_media.scss";
@import "../../node_modules/bootstrap/scss/_list-group.scss";
@import "../../node_modules/bootstrap/scss/_close.scss";
@import "../../node_modules/bootstrap/scss/_toasts.scss";
@import "../../node_modules/bootstrap/scss/_modal.scss";
@import "../../node_modules/bootstrap/scss/_tooltip.scss";
@import "../../node_modules/bootstrap/scss/_popover.scss";
@import "../../node_modules/bootstrap/scss/_carousel.scss";
@import "../../node_modules/bootstrap/scss/_spinners.scss";
@import "../../node_modules/bootstrap/scss/_utilities.scss";
@import "../../node_modules/bootstrap/scss/_print.scss";

// Import all here
@import "./fonts.scss";
@import "./header.scss";
@import "./images.scss";
@import "./banner.scss";
@import "./section.scss";
@import "./footer.scss";

html, body {
  width: 100%;
  height: 100%;
}

// body {
//   font-family: $font-family-montserrat;
// }