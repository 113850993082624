.banner {
  // min-height: 34.3125 !important;
  padding-top: 144px;

  &--dark {
    background-color: $orange;

    &--divider {
      height: 8.75rem;
      background-color: $secondary;
      border-bottom-left-radius: 12rem;
      border-bottom-right-radius: 12rem;
    }
  }

  &--light {
    background-color: $gray-100;
  }

  &--image {
    width: 36rem !important;
    height: 34.3125rem !important;
  }

  &--lexifi {
    width: 33.5625rem !important;
    height: 24.5625rem !important;
  }

  &--services {
    width: 45.3125rem !important;
    height: 27.1875rem !important;
  }

  &--about {
    width: 39.625rem !important;
    height: 25.3125rem !important;
  }

  &--focus {
    width: 41.0625rem !important;
    height: 25rem !important;
    // margin-top: -242px;
    // margin-left: auto;
    // margin-right: auto;
  }

  &--founder {
    width: 15rem !important;
    height: 17rem !important;
  }
  
  &--career {
    width: 33.5rem !important;
    height: 25.625rem !important;
  }

  &--contact {
    width: 21.0625rem !important;
    height: 18.8125rem !important;
  }
}

@media(max-width: 36rem) {
  .banner {
    &--image {
      width: 100% !important; 
      height: 100% !important;
      max-width: 36rem !important;
      max-height: 27.125 !important;
    }

    &--services {
      width: 18.875rem !important;
      height: 11.3125rem !important;
    }

    &--about {
      width: 18.875rem !important;
      height: 12.0625rem !important;
    }

    &--focus {
      width: 21.75rem !important;
      height: 14.1875rem !important;
    }

    &--lexifi {
      width: 18.875rem !important;
      height: 13.875rem !important;
    }

    &--career {
      width: 18.875rem !important;
      height: 14.4375rem !important;
    }
  }
}
